// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import rootReducer from './reducers/rootReducer';

// const initialState ={}

// // export default function configureStore() {
// //  return createStore(
// //   rootReducer,{},
// //    applyMiddleware(thunk)
// //  );
// // }

// export default createStore(rootReducer, initialState, applyMiddleware(thunk))


import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "./reducers/rootReducer";
// import {routerMiddleware} from "react-router-redux";
// import createHistory from "history/createBrowserHistory";
import thunk from 'redux-thunk';
// import history from "../../util/history"

// const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  const store = createStore(rootReducer, initialState,
    composeEnhancers(applyMiddleware(...middlewares)));

  
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers/rootReducer', () => {
      const nextRootReducer = require('./reducers/rootReducer');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}