import axios from "axios"
import {
  DEV_API_URI,
  auth_key,
  REQUEST_OTP_SUCCESS ,
  REQUEST_OTP_FAIL,
  REQUEST_RESET_PASS_OTP_SUCCESS,
  REQUEST_RESET_PASS_OTP_FAIL,
  RESET_PASS_FAIL,
  RESET_PASS_SUCCESS,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL ,
  VERIFY_PASSWORD_SUCCESS,
  VERIFY_PASSWORD_FAIL,
  FETCH_GAMES_LIST_FAIL,
  FETCH_GAMES_LIST_SUCCESS,
  JOIN_GAME_SUCCESS,
  JOIN_GAME_FAIL,
  USERS_LIST_SUCCESS,
  USERS_LIST_FAIL,
  USERS_CLAIM_LIST_SUCCESS,
  USERS_CLAIM_LIST_FAIL,
  GET_GAMES_RULES_SUCCESS,
  GET_GAMES_RULES_FAIL,
  SEND_DABB_NO_SUCCESS,
  SEND_DABB_NO_FAIL,
  CLAIM_REQUEST_SUCCESS,
  CLAIM_REQUEST_FAIL,
  WALLET_TRANSACTION_FAIL,
  WALLET_TRANSACTION_SUCCESS,
  VIEW_WALLET_SUCCESS,
  VIEW_WALLET_FAIL,
  VIEW_PROFILE_SUCCESS,
  VIEW_PROFILE_FAIL,
  GET_RANDOM_NUMBERS_SUCCESS,
  GET_RANDOM_NUMBERS_FAIL,
  LAST_PLAYED_LIST_SUCCESS,
  LAST_PLAYED_LIST_FAIL,
  GAME_INFORMATION_SUCCESS,
  GAME_INFORMATION_FAIL,
  GAME_TYPE_DETAILS_SUCCESS,
  GAME_TYPE_DETAILS_FAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  SCHEDULE_GAME_SUCCESS,
  SCHEDULE_GAME_FAIL,
  RETRIEVE_TICKETS_FAIL,
  RETRIEVE_TICKETS_SUCCESS,
  GAME_WINNER_FAIL,
  GAME_WINNER_SUCCESS,
  GET_IMAGE_FAIL,
  GET_IMAGE_SUCCESS,
  GET_MASTER_RULES_FAIL,
  GET_MASTER_RULES_SUCCESS,
  GET_DATE_SUCCESS,
  GET_DATE_FAIL,
  GET_ALL_GROUP_SUCCESS,
  GET_ALL_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  DELETE_PARTICIPANTS_SUCCESS,
  DELETE_PARTICIPANTS_FAIL,
  GET_MASTER_GROUPS_SUCCESS,
  GET_MASTER_GROUPS_FAIL,
  LAST_PLAYED_GAMES_PAGINATION_FAIL,
  LAST_PLAYED_GAMES_PAGINATION_SUCCESS,
  CREATE_GROUP_FAIL,
  CREATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  UPDATE_GROUP_SUCCESS,
  UPDATE_IMAGE_FAIL,
  UPDATE_IMAGE_SUCCESS,
  GROUP_DETAILS_SUCCESS,
  GROUP_DETAILS_FAIL,
  SUBMIT_APPROVAL_STATUS_SUCCESS,
  SUBMIT_APPROVAL_STATUS_FAIL,
  CREDIT_POINTS_SUCCESS,
  CREDIT_POINTS_FAIL,
  USER_STATUS_SUCCESS,
  USER_STATUS_FAIL,
  GET_GROUP_WISE_GAMES_SUCCESS,
  GET_GROUP_WISE_GAMES_FAIL,
  GET_ALL_GROUPS_LIST_SUCCESS,
  GET_ALL_GROUPS_LIST_FAIL,
  GET_APPROVAL_REQUESTS_SUCCESS,
  GET_APPROVAL_REQUESTS_FAIL,
  TOKEN_EXPIRES,
  LAST_FIVE_NUMBERS_FAIL,
  LAST_FIVE_NUMBERS_SUCCESS,
  START_GAME_SUCCESS,
  START_GAME_FAIL,
  GET_GAME_STATUS_SUCCESS,
  GET_GAME_STATUS_FAIL,
  GET_PARTICIPANT_IMAGE_FAIL,
  GET_PARTICIPANT_IMAGE_SUCCESS,
  RULES_SETTING_FAIL,
  RULES_SETTING_SUCCESS,
  REQUEST_MONEY_FAIL,
  REQUEST_MONEY_SUCCESS,
  GET_USERS_GAMES_FAIL,
  GET_USERS_GAMES_SUCCESS,
  GET_GAME_DETAILS_SUCCESS,
  GET_GAME_DETAILS_FAIL,
  GAME_DELETE_SUCCESS,
  GAME_DELETE_FAIL,
  GET_USER_PLAYING_GAME_SUCCESS,
  GET_USER_PLAYING_GAME_FAIL,
  GET_USER_TICKET_LIST_SUCCESS,
  GET_USER_TICKET_LIST_FAIL,
  RECHARGE_SUCCESS,
  RECHARGE_FAIL,
  GET_TRANSACTION_STATUS_FAIL,
  GET_TRANSACTION_STATUS_SUCCESS,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_FAIL,
  REFRESH_WALLET_TRANSACTION_FAIL,
  REFRESH_WALLET_TRANSACTION_SUCCESS,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL
} from "../constants/ActionType"
import ls from 'local-storage'
import { min } from "moment"

// const DEV_API_URI= http://15.206.153.105:8080/

export const simpleAction = () => dispatch => {
    dispatch({
     type: 'SIMPLE_ACTION',
     payload: 'result_of_simple_action'
    })
}

export const requestOtp = (mobileNumber) => {
  return function (dispatch) {
    //dispatch(startDashBoardProgress());
    axios.get(`${DEV_API_URI}/api/otp/generateOtp/${mobileNumber}`,{
      headers : {
        Authorization : auth_key,
        ContentType : "application/json"
      }
    })
      .then((data) => {
        dispatch({
          type: REQUEST_OTP_SUCCESS,
          payload: data ? data : []
        });
      })
      .catch((error) => {
        dispatch({
          type: REQUEST_OTP_FAIL,
          payload: null
        });
      });
  }
} 

export const requestResetPasswordOtp = (mobileNumber) => {
  return function (dispatch) {
    //dispatch(startDashBoardProgress());
    axios.get(`${DEV_API_URI}/api/otp/generateresetpasswordOtp/${mobileNumber}`,{
      headers : {
        Authorization : auth_key,
        ContentType : "application/json"
      }
    })
      .then((data) => {
        dispatch({
          type: REQUEST_RESET_PASS_OTP_SUCCESS,
          payload: data ? data : []
        });
      })
      .catch((error) => {
        dispatch({
          type: REQUEST_RESET_PASS_OTP_FAIL,
          payload: null
        });
      });
  }
} 

export const ResetPassword = (mobileNumber,otp,password) => {
  return function (dispatch) {
    //dispatch(startDashBoardProgress());
    axios.get(`${DEV_API_URI}/api/otp/verifyresetpasswordOtp/${mobileNumber}/${otp}/${password}`,{
      headers : {
        Authorization : auth_key,
        ContentType : "application/json"
      }
    })
      .then((data) => {
        dispatch({
          type: RESET_PASS_SUCCESS,
          payload: data ? data : []
        });
      })
      .catch((error) => {
        dispatch({
          type: RESET_PASS_FAIL,
          payload: null
        });
      });
  }
} 


export const verifyRequestOtp = (mobileNumber , otp, referralCode) => {
  return function (dispatch) {
    //dispatch(startDashBoardProgress());
    var url=`${DEV_API_URI}/api/otp/verifyOtp/${mobileNumber}/${otp}`;
    if(referralCode != ""){
      url=`${DEV_API_URI}/api/otp/verifyRefferalOtp/${mobileNumber}/${otp}/${referralCode}`;
    }
    axios.get(url,{
      headers : {
        Authorization : auth_key,
        ContentType : "application/json"
      }
    })
      .then((data) => {
        dispatch({
          type: VERIFY_OTP_SUCCESS,
          payload: data ? data : []
        });
      })
      .catch((error) => {
        dispatch({
          type: VERIFY_OTP_FAIL,
          payload: null
        });
      });
  }
}

export const verifyRequestPassword = (mobileNumber , password) => {
  return function (dispatch) {
    //dispatch(startDashBoardProgress());
    axios.get(`${DEV_API_URI}/api/otp/verifyPassword/${mobileNumber}/${password}`,{
      headers : {
        Authorization : auth_key,
        ContentType : "application/json"
      }
    })
      .then((data) => {
        dispatch({
          type: VERIFY_PASSWORD_SUCCESS,
          payload: data ? data : []
        });
      })
      .catch((error) => {
        dispatch({
          type: VERIFY_PASSWORD_FAIL,
          payload: null
        });
      });
  }
}

export const getTodayGames = () => {
  return function (dispatch) {
    //dispatch(startDashBoardProgress());
    axios.get(`${DEV_API_URI}/api/games/1/groupgamesfortoday`,{
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      }
    })
      .then((data) => {
        dispatch({
          type: FETCH_GAMES_LIST_SUCCESS,
          payload: data ? data : []
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
        ls.clear();   
        }
        dispatch({
          type: FETCH_GAMES_LIST_FAIL,
          payload: null
        });
      });
  }
}

export const joinGame = (userId , gameId , noOfTickets) => {
  return function (dispatch) {

    axios({
      method: 'post',
      url: `${DEV_API_URI}/api/games/joingame`,
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
      data: {
        userId: userId,
        gameId: gameId,
        noOfTickets: noOfTickets
      }
    })
    .then((data) => {
      dispatch({
        type: JOIN_GAME_SUCCESS,
        payload: data ? data : []
      });
    })
    .catch((error) => {
      if(error.message == "Request failed with status code 403"){
        dispatch({
          type: TOKEN_EXPIRES,
          payload: null
        });
        ls.clear();   

      }
      dispatch({
        type: JOIN_GAME_FAIL,
        payload: null
      });
    });
    //dispatch(startDashBoardProgress());
}
}

export const usersList = (gameId) => {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${DEV_API_URI}/api/games/${gameId}/users`,
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
    })
    .then((data) => {
      dispatch({
        type: USERS_LIST_SUCCESS,
        payload: data ? data : []
      });
    })
    .catch((error) => {
      if(error.message == "Request failed with status code 403"){
        dispatch({
          type: TOKEN_EXPIRES,
          payload: null
        });   
        ls.clear();   

      }
      dispatch({
        type: USERS_LIST_FAIL,
        payload: null
      });
    });
    //dispatch(startDashBoardProgress());
}
}

export const usersClaimList = (gameId) => {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${DEV_API_URI}/api/games/${gameId}/usersclaims`,
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
    })
    .then((data) => {
      dispatch({
        type: USERS_CLAIM_LIST_SUCCESS,
        payload: data ? data : []
      });
    })
    .catch((error) => {
      if(error.message == "Request failed with status code 403"){
        dispatch({
          type: TOKEN_EXPIRES,
          payload: null
        });   
        ls.clear();   

      }
      dispatch({
        type: USERS_CLAIM_LIST_FAIL,
        payload: null
      });
    });
    //dispatch(startDashBoardProgress());
}
}

export const getRulesList = (gameId) => {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${DEV_API_URI}/api/games/${gameId}/gamesrules`,
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
    })
    .then((data) => {
      dispatch({
        type: GET_GAMES_RULES_SUCCESS,
        payload: data ? data : []
      });
    })
    .catch((error) => {
      if(error.message == "Request failed with status code 403"){
        dispatch({
          type: TOKEN_EXPIRES,
          payload: null
        });  
        ls.clear();   

      }
      dispatch({
        type: GET_GAMES_RULES_FAIL,
        payload: null
      });
    });
    //dispatch(startDashBoardProgress());
}
}

export const sendDabbedNumber = (numberId) => {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${DEV_API_URI}/api/games/dabbednumber/${numberId}`,
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
    })
    .then((data) => {
      dispatch({
        type: SEND_DABB_NO_SUCCESS,
        payload: data ? data : []
      });
    })
    .catch((error) => {
      if(error.message == "Request failed with status code 403"){
        dispatch({
          type: TOKEN_EXPIRES,
          payload: null
        });     
        ls.clear();   

      }
      dispatch({
        type: SEND_DABB_NO_FAIL,
        payload: null
      });
    });
    //dispatch(startDashBoardProgress());
}
}

export const sendClaimRequest = (ticketId,ruleId) => {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${DEV_API_URI}/api/games/${ticketId}/claim/${ruleId}`,
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
    })
    .then((data) => {
      dispatch({
        type: CLAIM_REQUEST_SUCCESS,
        payload: data ? data : []
      });
    })
    .catch((error) => {
      if(error.message == "Request failed with status code 403"){
        dispatch({
          type: TOKEN_EXPIRES,
          payload: null
        });      
        ls.clear();   

      }
      dispatch({
        type: CLAIM_REQUEST_FAIL,
        payload: null
      });
    });
    //dispatch(startDashBoardProgress());
}
}

export const viewWallet = () => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/wallet/viewWallet`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
    })
      .then((data) => {
        dispatch({
          type: VIEW_WALLET_SUCCESS,
          payload: data ? data : [],          
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();    
        }
        dispatch({
          type: VIEW_WALLET_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const walletTransaction = (walletId, pageNo, pageSize) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/wallet/viewWalletTransaction/${walletId}/${pageNo}/${pageSize}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: WALLET_TRANSACTION_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();       
        }
        dispatch({
          type: WALLET_TRANSACTION_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const viewProfile = () => {
  return function (dispatch) {
    //dispatch(startDashBoardProgress());
    axios.get(`${DEV_API_URI}/api/user/getProfile`,{
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      }
    })
      .then((data) => {
        dispatch({
          type: VIEW_PROFILE_SUCCESS,
          payload: data ? data : []
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();  
        }
        dispatch({
          type: VIEW_PROFILE_FAIL,
          payload: null
        });
      });
  }
}

export const getRandomNumbers = (gameId) => {
  return function (dispatch) {
    //dispatch(startDashBoardProgress());
    axios.get(`${DEV_API_URI}/api/games/${gameId}/randomnumbers`,{
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      }
    })
      .then((data) => {
        dispatch({
          type: GET_RANDOM_NUMBERS_SUCCESS,
          payload: data ? data : []
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();      
        }
        dispatch({
          type: GET_RANDOM_NUMBERS_FAIL,
          payload: null
        });
      });
  }
}

export const lastPlayedGamesList = () => {
  return function (dispatch) {
    //dispatch(startDashBoardProgress());
    axios
      .get(`${DEV_API_URI}/api/user/getGamePlayed`, {
        headers: {
          Authorization: auth_key,
          ContentType: "application/json",
          "X-Authorization": ls.get("authTokens"),
        },
      })
      .then((data) => {
        dispatch({
          type: LAST_PLAYED_LIST_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();    
        }
        dispatch({
          type: LAST_PLAYED_LIST_FAIL,
          payload: null,
        });
      });
  };
};

export const gameInformation = (gameId) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/${gameId}/gameinformation`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GAME_INFORMATION_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();   
        }
        dispatch({
          type: GAME_INFORMATION_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const gameTypeDetails = () => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/gamestypes`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GAME_TYPE_DETAILS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();    
        }
        dispatch({
          type: GAME_TYPE_DETAILS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const updateProfile = (formData) => {
  
  return function (dispatch) {

    axios({
      method: 'put',
      url: `${DEV_API_URI}/api/user/updateProfile`,
      data: formData,
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
    //  formData
    })
    .then((data) => {
      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: data ? data : []
      });
    })
    .catch((error) => {
      if(error.message == "Request failed with status code 403"){
        dispatch({
          type: TOKEN_EXPIRES,
          payload: null
        }); 
        ls.clear();       
      }

      dispatch({
        type: UPDATE_PROFILE_FAIL,
        payload: null
      });
    });
    //dispatch(startDashBoardProgress());
}
}

export const schedulegame = (
  groupId,
  maxTickets,
  gameType,
  startTime,
  gamesRuleInformations,
  costPrice,
  gameMode,
  approvalRequired,
  commission,
  formulaDriven,
  maxTicketsAllowedPerUser,
  preAllotcateTickets,
  preAllotedTickets,
  maxIntervalBetweenNumbers,
  chargeEntryFee,
  minTickets
) => {
  return function (dispatch) {
    axios({
      method: "post",
      url: `${DEV_API_URI}/api/games/schedulegame`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
      data: {
        gameType: { id: gameType },
        groupId: groupId,
        maxTickets: maxTickets,
        startTime: startTime,
        status: "AVAILABLE",
        gameName: "",
        costPrice: costPrice,
        userId: ls.get("userId"),
        gamesRuleInformations: gamesRuleInformations,
        modeOfPlay: gameMode,
        approvalRequired :approvalRequired,
        commission :commission ,
        formulaDriven : formulaDriven,
        maxTicketsAllowedPerUser : maxTicketsAllowedPerUser ,
        preAllotcateTickets : preAllotcateTickets,
        preAllotedTickets : preAllotedTickets,
        maxIntervalBetweenNumbers : maxIntervalBetweenNumbers,
        chargeEntryFee : chargeEntryFee,
        minTickets : minTickets
      },
    })
      .then((data) => {
        dispatch({
          type: SCHEDULE_GAME_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 403") {
          ls.clear();
        }
        dispatch({
          type: SCHEDULE_GAME_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const retrieveGameTickets = (gameId) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/${gameId}/retrievetickets`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: RETRIEVE_TICKETS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();  
        }
        dispatch({
          type: RETRIEVE_TICKETS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const winnerList = (gameId) => { 
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${DEV_API_URI}/api/games/${gameId}/gamewinniginformation`,
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
    })
    .then((data) => {
      dispatch({
        type: GAME_WINNER_SUCCESS,
        payload: data ? data : []
      });
    })
    .catch((error) => {
      if(error.message == "Request failed with status code 403"){
        dispatch({
          type: TOKEN_EXPIRES,
          payload: null
        });     
        ls.clear();    
      }
      dispatch({
        type: GAME_WINNER_FAIL,
        payload: null
      });
    });
    //dispatch(startDashBoardProgress());
}
}

export const getImage = (photoId) => { 
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${DEV_API_URI}/api/file/${photoId}`,
      responseType: 'arraybuffer',
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
    })
    .then((data) => {
      dispatch({
        type: GET_IMAGE_SUCCESS,
        payload: data ? data : []
      });
    })
    .catch((error) => {
      if(error.message == "Request failed with status code 403"){
        dispatch({
          type: TOKEN_EXPIRES,
          payload: null
        });     
        ls.clear();    
      }
      dispatch({
        type: GET_IMAGE_FAIL,
        payload: null
      });
    });
    //dispatch(startDashBoardProgress());
}
}

export const getMasterRules = (gameType) => { 
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${DEV_API_URI}/api/games/${gameType}/gamestyperules`,
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
    })
    .then((data) => {
      dispatch({
        type: GET_MASTER_RULES_SUCCESS,
        payload: data ? data : []
      });
    })
    .catch((error) => {
      if(error.message == "Request failed with status code 403"){
        dispatch({
          type: TOKEN_EXPIRES,
          payload: null
        });   
        ls.clear();      
      }
      dispatch({
        type: GET_MASTER_RULES_FAIL,
        payload: null
      });
    });
    //dispatch(startDashBoardProgress());
}
}

export const getCurrentDateTime = () => { 
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${DEV_API_URI}/api/games/current-date-time`,
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
    })
    .then((data) => {
      dispatch({
        type: GET_DATE_SUCCESS,
        payload: data ? data : []
      });
     
    })
    .catch((error) => {
      if(error.message == "Request failed with status code 403"){
        dispatch({
          type: TOKEN_EXPIRES,
          payload: null
        });   
        ls.clear();      
      }
      dispatch({
        type: GET_DATE_FAIL,
        payload: null
      });
    });
    //dispatch(startDashBoardProgress());
}
}

export const getAllGroup = (currentPage, postsPerPage) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/group/getAllGroups/${currentPage}/${postsPerPage}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GET_ALL_GROUP_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();      
        }
        dispatch({
          type: GET_ALL_GROUP_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
}

export const deleteGroup = (groupId) => {
  return function (dispatch) {
    axios({
      method: "delete",
      url: `${DEV_API_URI}/api/group/deletegroup/${groupId}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: DELETE_GROUP_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();  
        }
        dispatch({
          type: DELETE_GROUP_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const deleteParticipants = (id) => {
  return function (dispatch) {
    axios({
      method: "delete",
      url: `${DEV_API_URI}/api/group/deleteparticipants/${id}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: DELETE_PARTICIPANTS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();    
        }
        dispatch({
          type: DELETE_PARTICIPANTS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const getMasterGroups = () => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/groupslist`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GET_MASTER_GROUPS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }
        dispatch({
          type: GET_MASTER_GROUPS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const createGroup = (groupName, participantsNumber, participants) => {
  return function (dispatch) {
    axios({
      method: "post",
      url: `${DEV_API_URI}/api/group/creategroup`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
      data: {
        groupName: groupName,
        participantsNumber: participantsNumber,
        participants: participants,
      },
    })
      .then((data) => {
        dispatch({
          type: CREATE_GROUP_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();  
        }
        dispatch({
          type: CREATE_GROUP_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const updateGroup = (groupName, participants, groupId) => {
  return function (dispatch) {
    axios({
      method: "put",
      url: `${DEV_API_URI}/api/group/updategroup/${groupId}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
      data: {
        groupName: groupName,
        participants: participants,
      },
    })
      .then((data) => {
        dispatch({
          type: UPDATE_GROUP_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();      
        }
        dispatch({
          type: UPDATE_GROUP_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const lastPlayedGamesPagination = (pageNo, pageSize) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/user/getGamePlayed/${pageNo}/${pageSize}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: LAST_PLAYED_GAMES_PAGINATION_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();  
        }
        dispatch({
          type: LAST_PLAYED_GAMES_PAGINATION_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const updateImage = (file) => {  
  let formData =new FormData
  formData.append('photo',file)
  return function (dispatch) {
    axios({
      method: 'put',
      url: `${DEV_API_URI}/api/user/updateImage`,
      data: formData,
      headers : {
        Authorization : auth_key,
        ContentType : "application/json",
        "X-Authorization" : ls.get('authTokens')
      },
    })
    .then((data) => {
      dispatch({
        type: UPDATE_IMAGE_SUCCESS,
        payload: data ? data : []
      });
    })
    .catch((error) => {
      if(error.message == "Request failed with status code 403"){
        dispatch({
          type: TOKEN_EXPIRES,
          payload: null
        });  
      ls.clear();

      }

      dispatch({
        type: UPDATE_IMAGE_FAIL,
        payload: null
      });
    });
    //dispatch(startDashBoardProgress());
}
}

export const groupsDetails = (id) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/group/groupinformation/${id}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GROUP_DETAILS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();  
        }
        dispatch({
          type: GROUP_DETAILS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const submitApprovalStatus = (status,user_game_id,no_of_tickets) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/${user_game_id}/approvestatus/${status}/${no_of_tickets}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: SUBMIT_APPROVAL_STATUS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }
        dispatch({
          type: SUBMIT_APPROVAL_STATUS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const creditPoints = (mobileNumber, points, remarks) => {
  return function (dispatch) {
    axios({
      method: "post",
      url: `${DEV_API_URI}/api/wallet/creditwallet`,
      headers: {
        Authorization: "apikey e4a233a6-ba0f-4bc4-9d43-cc02d9ab27ee",
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
      data: {
        userId: mobileNumber,
        points: points,
        remarks: remarks
      },
    })
      .then((data) => {
        dispatch({
          type: CREDIT_POINTS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }  
        dispatch({
          type: CREDIT_POINTS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const getStatus = (game_id) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/${game_id}/gamestatus`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: USER_STATUS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        } 
        dispatch({
          type: USER_STATUS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const getGroupWiseGames = (gameType,groupId) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/${gameType}/${groupId}/groupgamesfortoday`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GET_GROUP_WISE_GAMES_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        } 
        dispatch({
          type: GET_GROUP_WISE_GAMES_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const getAllGrp = () => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/groups`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GET_ALL_GROUPS_LIST_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }  
        dispatch({
          type: GET_ALL_GROUPS_LIST_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const getApprovalOrJoinGameRequests  = (gametypeid) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/${gametypeid}/approvalorjoingamerequests`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GET_APPROVAL_REQUESTS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }  
        dispatch({
          type: GET_APPROVAL_REQUESTS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const lastFiveNumbers = (game_id) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/${game_id}/fiverandomnumbers`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: LAST_FIVE_NUMBERS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
          ls.clear();     
        }  
        dispatch({
          type: LAST_FIVE_NUMBERS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const changeGameStatus = (game_id,status) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/gamecurrentstatus/${game_id}/${status}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: START_GAME_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }  
        dispatch({
          type: START_GAME_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const getCurrentGameStatus = (game_id) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/${game_id}/info`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GET_GAME_STATUS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }  
        dispatch({
          type: GET_GAME_STATUS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const getParticipantImage = (user_id) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/group/getimage/${user_id}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GET_PARTICIPANT_IMAGE_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
          ls.clear();     
        }  
        dispatch({
          type: GET_PARTICIPANT_IMAGE_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const getRulesSetting = () => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/lastFiveGameCreated`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: RULES_SETTING_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
          ls.clear();     
        }  
        dispatch({
          type: RULES_SETTING_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const RequestMoney = (userId, points, mode ,mobileNumber,emailId ,name,address1, address2, city, state, zip) => {
  return function (dispatch) {
    axios({
      method: "post",
      url: `${DEV_API_URI}/api/wallet/redeem-points`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
      data: {
        userId,
        points,
        mode,
        mobileNumber,
        emailId,
        name,
        address1,
        address2,
        city,
        state,
        zip
      },
    })
      .then((data) => {
        dispatch({
          type: REQUEST_MONEY_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
          ls.clear();     
        }  
        dispatch({
          type: REQUEST_MONEY_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const useUsersGame = (pageNo,perPage) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/allgamescreated/${pageNo}/${perPage}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GET_USERS_GAMES_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
          ls.clear();     
        }  
        dispatch({
          type: GET_USERS_GAMES_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const updateGame = (
  game_id,
  groupId,
  maxTickets,
  gameType,
  startTime,
  gamesRuleInformations,
  costPrice,
  gameMode,
  approvalRequired,
  commission,
  status,
  formulaDriven,
  maxTicketsAllowedPerUser,
  preAllotcateTickets,
  preAllotedTickets,
  maxIntervalBetweenNumbers,
  chargeEntryFee,
  minTickets) => {
  return function (dispatch) {
    axios({
      method: "put",
      url: `${DEV_API_URI}/api/games/${game_id}/updategame`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
      data: {
        gameType: { id: gameType },
        groupId: groupId,
        maxTickets: maxTickets,
        startTime: startTime,
        status: status,
        gameName: "",
        costPrice: costPrice,
        userId: ls.get("userId"),
        gamesRuleInformations: gamesRuleInformations,
        modeOfPlay: gameMode,
        approvalRequired :approvalRequired,
        commission :commission,
        formulaDriven : formulaDriven,
        maxTicketsAllowedPerUser : maxTicketsAllowedPerUser ,
        preAllotcateTickets : preAllotcateTickets,
        preAllotedTickets : preAllotedTickets,
        maxIntervalBetweenNumbers : maxIntervalBetweenNumbers,
        chargeEntryFee: chargeEntryFee,
        minTickets: minTickets
      },
    })
      .then((data) => {
        dispatch({
          type: GET_GAME_DETAILS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
          ls.clear();     
        }  
        dispatch({
          type: GET_GAME_DETAILS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const deleteGame = (
  game_id,
   ) => {
  return function (dispatch) {
    axios({
      method: "delete",
      url: `${DEV_API_URI}/api/games/${game_id}/deletegame`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
      data: {
       gameId : game_id
      },
    })
      .then((data) => {
        dispatch({
          type: GAME_DELETE_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
          ls.clear();     
        }  
        dispatch({
          type: GAME_DELETE_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const getUserPlayingGame = (game_id) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/${game_id}/userplayinglist`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GET_USER_PLAYING_GAME_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }  
        dispatch({
          type: GET_USER_PLAYING_GAME_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const getUserTicketList = (game_id,userId) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/games/${game_id}/listoftickets/${userId}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GET_USER_TICKET_LIST_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }  
        dispatch({
          type: GET_USER_TICKET_LIST_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const rechargeWallet = (modeOfPay,amount,gameId) => {
  return function (dispatch) {
    axios({
      method: "post",
      url: `${DEV_API_URI}/api/wallet/add-money?lang=en`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
      data : {
        "paymentMethod": modeOfPay,
        "amount":amount,
        "gameId" : gameId
      }
    })
      .then((data) => {
        dispatch({
          type: RECHARGE_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
          ls.clear();
               
        }  
        dispatch({
          type: RECHARGE_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const getTransactionStatus = (orderId) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/wallet/view-transaction/${orderId}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: GET_TRANSACTION_STATUS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }  
        dispatch({
          type: GET_TRANSACTION_STATUS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};

export const removeParticipant = (userId, gameId) => {
  return function (dispatch) {
    axios({
      method: "delete",
      url: `${DEV_API_URI}/api/games/${gameId}/removeuser/${userId}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: REMOVE_USER_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }  
        dispatch({
          type: REMOVE_USER_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};



export const refreshTransaction = (id) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/wallet/check-status/${id}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: REFRESH_WALLET_TRANSACTION_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }  
        dispatch({
          type: REFRESH_WALLET_TRANSACTION_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};


export const getUserDetails = (id) => {
  return function (dispatch) {
    axios({
      method: "get",
      url: `${DEV_API_URI}/api/user/userdetails/${id}`,
      headers: {
        Authorization: auth_key,
        ContentType: "application/json",
        "X-Authorization": ls.get("authTokens"),
      },
    })
      .then((data) => {
        dispatch({
          type: USER_DETAILS_SUCCESS,
          payload: data ? data : [],
        });
      })
      .catch((error) => {
        if(error.message == "Request failed with status code 403"){
          dispatch({
            type: TOKEN_EXPIRES,
            payload: null
          });  
ls.clear();     
        }  
        dispatch({
          type: USER_DETAILS_FAIL,
          payload: null,
        });
      });
    //dispatch(startDashBoardProgress());
  };
};



