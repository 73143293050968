export const DEV_API_URI = "https://game.gotambola.com"
// export const SOCKET_URI = "http://game.gotambola.com:7070"
export const auth_key = "apikey e4a233a6-ba0f-4bc4-9d43-cc02d9ab27ee"

export const REQUEST_OTP_SUCCESS = "REQUEST_OTP_SUCCESS"
export const REQUEST_OTP_FAIL = "REQUEST_OTP_FAIL"

export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS"
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL"

export const VERIFY_PASSWORD_SUCCESS = "VERIFY_PASSWORD_SUCCESS"
export const VERIFY_PASSWORD_FAIL = "VERIFY_PASSWORD_SUCCESS"

export const REQUEST_RESET_PASS_OTP_SUCCESS = "REQUEST_RESET_PASS_OTP_SUCCESS"
export const REQUEST_RESET_PASS_OTP_FAIL = "REQUEST_RESET_PASS_OTP_FAIL"

export const RESET_PASS_FAIL = "RESET_PASS_FAIL"
export const RESET_PASS_SUCCESS = "RESET_PASS_SUCCESS"

export const FETCH_GAMES_LIST_FAIL = "FETCH_GAMES_LIST_FAIL"
export const FETCH_GAMES_LIST_SUCCESS = "FETCH_GAMES_LIST_SUCCESS"

export const JOIN_GAME_FAIL = "JOIN_GAME_FAIL"
export const JOIN_GAME_SUCCESS = "JOIN_GAME_SUCCESS"

export const USERS_LIST_FAIL = "USERS_LIST_FAIL"
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS" 

export const USERS_CLAIM_LIST_FAIL = "USERS_CLAIM_LIST_FAIL"
export const USERS_CLAIM_LIST_SUCCESS = "USERS_CLAIM_LIST_SUCCESS"

export const GET_GAMES_RULES_FAIL = "GET_GAMES_RULES_FAIL"
export const GET_GAMES_RULES_SUCCESS = "GET_GAMES_RULES_SUCCESS"

export const SEND_DABB_NO_FAIL = "SEND_DABB_NO_FAIL"
export const SEND_DABB_NO_SUCCESS  = "SEND_DABB_NO_SUCCESS"

export const CLAIM_REQUEST_FAIL = "CLAIM_REQUEST_FAIL"
export const CLAIM_REQUEST_SUCCESS  = "CLAIM_REQUEST_SUCCESS"

export const WALLET_TRANSACTION_FAIL = "WALLET_TRANSACTION_FAIL";
export const WALLET_TRANSACTION_SUCCESS = "WALLET_TRANSACTION_SUCCESS";

export const VIEW_WALLET_FAIL = "VIEW_WALLET_FAIL";
export const VIEW_WALLET_SUCCESS = "VIEW_WALLET_SUCCESS";

export const VIEW_PROFILE_FAIL = "VIEW_PROFILE_FAIL";
export const VIEW_PROFILE_SUCCESS = "VIEW_PROFILE_SUCCESS";

export const GET_RANDOM_NUMBERS_FAIL = "GET_RANDOM_NUMBERS_FAIL";
export const GET_RANDOM_NUMBERS_SUCCESS = "GET_RANDOM_NUMBERS_SUCCESS";

export const LAST_PLAYED_LIST_FAIL = "LAST_PLAYED_LIST_FAIL";
export const LAST_PLAYED_LIST_SUCCESS = "LAST_PLAYED_LIST_SUCCESS";

export const GAME_INFORMATION_FAIL = "GAME_INFORMATION_FAIL";
export const GAME_INFORMATION_SUCCESS = "GAME_INFORMATION_SUCCESS";

export const GAME_TYPE_DETAILS_FAIL = "GAME_TYPE_DETAILS_FAIL";
export const GAME_TYPE_DETAILS_SUCCESS = "GAME_TYPE_DETAILS_SUCCESS";

export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"

export const SCHEDULE_GAME_FAIL = "SCHEDULE_GAME_FAIL"
export const SCHEDULE_GAME_SUCCESS = "SCHEDULE_GAME_SUCCESS"

export const RETRIEVE_TICKETS_FAIL = "RETRIEVE_TICKETS_FAIL"
export const RETRIEVE_TICKETS_SUCCESS = "RETRIEVE_TICKETS_SUCCESS"
  
export const GAME_WINNER_FAIL = "GAME_WINNER_FAIL"
export const GAME_WINNER_SUCCESS = "GAME_WINNER_SUCCESS"

export const GET_IMAGE_FAIL = "GET_IMAGE_FAIL"
export const GET_IMAGE_SUCCESS = "GET_IMAGE_SUCCESS"

export const GET_MASTER_RULES_FAIL = "GET_MASTER_RULES_FAIL"
export const GET_MASTER_RULES_SUCCESS = "GET_MASTER_RULES_SUCCESS"

export const GET_DATE_FAIL = "GET_DATE_FAIL"
export const GET_DATE_SUCCESS = "GET_DATE_SUCCESS"

export const GET_ALL_GROUP_FAIL = "GET_ALL_GROUP_FAIL"
export const GET_ALL_GROUP_SUCCESS = "GET_ALL_GROUP_SUCCESS"

export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL"
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS"

export const DELETE_PARTICIPANTS_FAIL = "DELETE_PARTICIPANTS_FAIL"
export const DELETE_PARTICIPANTS_SUCCESS = "DELETE_PARTICIPANTS_SUCCESS"

export const GET_MASTER_GROUPS_FAIL = "GET_MASTER_GROUPS_FAIL"
export const GET_MASTER_GROUPS_SUCCESS = "GET_MASTER_GROUPS_SUCCESS"

export const CREATE_GROUP_FAIL = "CREATE_GROUP_FAIL";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";

export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";

export const LAST_PLAYED_GAMES_PAGINATION_FAIL = "LAST_PLAYED_GAMES_PAGINATION_FAILED";
export const LAST_PLAYED_GAMES_PAGINATION_SUCCESS = "LAST_PLAYED_GAMES_PAGINATION_SUCCESS";

export const UPDATE_IMAGE_FAIL = "UPDATE_IMAGE_FAIL";
export const UPDATE_IMAGE_SUCCESS = "UPDATE_IMAGE_SUCCESS";

export const GROUP_DETAILS_FAIL = "GROUP_DETAILS_FAIL";
export const GROUP_DETAILS_SUCCESS = "GROUP_DETAILS_SUCCESS";
  
export const SUBMIT_APPROVAL_STATUS_FAIL = "SUBMIT_APPROVAL_STATUS_FAIL";
export const SUBMIT_APPROVAL_STATUS_SUCCESS = "SUBMIT_APPROVAL_STATUS_SUCCESS";
  
export const CREDIT_POINTS_FAIL = "CREDIT_POINTS_FAIL";
export const CREDIT_POINTS_SUCCESS = "CREDIT_POINTS_SUCCESS";

export const USER_STATUS_FAIL = "USER_STATUS_FAIL";
export const USER_STATUS_SUCCESS = "USER_STATUS_SUCCESS";

export const GET_GROUP_WISE_GAMES_FAIL = "GET_GROUP_WISE_GAMES_FAIL";
export const GET_GROUP_WISE_GAMES_SUCCESS = "GET_GROUP_WISE_GAMES_SUCCESS";

export const GET_ALL_GROUPS_LIST_FAIL = "GET_ALL_GROUPS_LIST_FAIL";
export const GET_ALL_GROUPS_LIST_SUCCESS = "GET_ALL_GROUPS_LIST_SUCCESS";

export const GET_APPROVAL_REQUESTS_FAIL = "GET_APPROVAL_REQUESTS_FAIL";
export const GET_APPROVAL_REQUESTS_SUCCESS = "GET_APPROVAL_REQUESTS_SUCCESS";

export const LAST_FIVE_NUMBERS_FAIL = "LAST_FIVE_NUMBERS_FAIL";
export const LAST_FIVE_NUMBERS_SUCCESS = "LAST_FIVE_NUMBERS_SUCCESS";

export const START_GAME_SUCCESS = "START_GAME_SUCCESS";
export const START_GAME_FAIL = "START_GAME_FAIL";

export const GET_GAME_STATUS_SUCCESS = "GET_GAME_STATUS_SUCCESS";
export const GET_GAME_STATUS_FAIL = "GET_GAME_STATUS_FAIL";

export const GET_PARTICIPANT_IMAGE_SUCCESS = "GET_PARTICIPANT_IMAGE_SUCCESS";
export const GET_PARTICIPANT_IMAGE_FAIL = "GET_PARTICIPANT_IMAGE_FAIL";

export const RULES_SETTING_SUCCESS = "RULES_SETTING_SUCCESS";
export const RULES_SETTING_FAIL = "RULES_SETTING_FAIL";  

export const REQUEST_MONEY_SUCCESS = "REQUEST_MONEY_SUCCESS";
export const REQUEST_MONEY_FAIL = "REQUEST_MONEY_FAIL";  

export const GET_USERS_GAMES_SUCCESS = "GET_USERS_GAMES_SUCCESS";
export const GET_USERS_GAMES_FAIL = "GET_USERS_GAMES_FAIL"; 

export const GET_GAME_DETAILS_SUCCESS = "GET_GAME_DETAILS_SUCCESS";
export const GET_GAME_DETAILS_FAIL = "GET_GAME_DETAILS_FAIL";  

export const GAME_DELETE_SUCCESS = "GAME_DELETE_SUCCESS";
export const GAME_DELETE_FAIL = "GAME_DELETE_FAIL"; 

export const GET_USER_PLAYING_GAME_SUCCESS = "GET_USER_PLAYING_GAME_SUCCESS";
export const GET_USER_PLAYING_GAME_FAIL = "GET_USER_PLAYING_GAME_FAIL";

export const GET_USER_TICKET_LIST_SUCCESS = "GET_USER_TICKET_LIST_SUCCESS";
export const GET_USER_TICKET_LIST_FAIL = "GET_USER_TICKET_LIST_FAIL";

export const RECHARGE_SUCCESS = "RECHARGE_SUCCESS";
export const RECHARGE_FAIL = "RECHARGE_FAIL";

export const GET_TRANSACTION_STATUS_SUCCESS = "GET_TRANSACTION_STATUS_SUCCESS";
export const GET_TRANSACTION_STATUS_FAIL = "GET_TRANSACTION_STATUS_FAIL";

export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export const REMOVE_USER_FAIL = "REMOVE_USER_FAIL";

export const REFRESH_WALLET_TRANSACTION_SUCCESS = "REFRESH_WALLET_TRANSACTION_SUCCESS";
export const REFRESH_WALLET_TRANSACTION_FAIL = "REFRESH_WALLET_TRANSACTION_FAIL";

export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const TOKEN_EXPIRES = "TOKEN_EXPIRES";

  

  
