import React from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "./context/auth";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Provider } from 'react-redux'
import configureStore from './store';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { CometChat } from "@cometchat-pro/chat"

export const store = configureStore();

ReactDOM.render(
<BrowserRouter>
 <Provider store={store}>
  <App />
 </Provider>
 </BrowserRouter>,
 document.getElementById('root')
);