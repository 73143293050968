import React, { Component, useState } from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { AuthContext } from "./context/auth";
import ls from "local-storage";
import homeImg from './static/img/home.svg'
import refreshImg from './static/img/refresh_icon.png'
// import PrivateRoute from './PrivateRoute';
import "./static/css/style.css";
import "./static/css/bootstrap.min.css";
import PointsImg from "./static/img/business-and-finance.svg"
// import { AuthContext } from "./context/auth";
import walletImg from "./static/img/wallet.svg";
import userImg from "./static/img/user.svg";
import addgameImg from "./static/img/add.svg";
import nextImg from "./static/img/next.svg";
import moment from 'moment'
import { DEV_API_URI} from './constants/ActionType'
import Modal from 'react-modal'
import logo from "./static/img/appLogo.png"
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import * as Action from "./actions/simpleAction";
import { connect } from "react-redux";
import { NotificationContainer ,NotificationManager } from "react-notifications";
import 'react-notifications/lib/notifications.css'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import jwt_decode from 'jwt-decode'
import callImg from './static/img/call.svg'
import { CometChat } from "@cometchat-pro/chat"
import ReactTooltip from 'react-tooltip'
const loading = () => (
  <div class="animated fadeIn pt-3 text-center">Loading...</div>
);
const EditGroup = React.lazy(() => import("./views/CreateGroup/EditGroup.js"));
const Login = React.lazy(() => import("./views/Login/Login.js"));
const Page404 = React.lazy(() => import("./views/Page404/Page404.js"));
const SignUp = React.lazy(() => import("./views/Login/SignUp.js"));
const ResetPassword = React.lazy(() => import("./views/Login/ResetPassword.js"));
const Dashboard = React.lazy(() => import("./views/Dashboard/Dashboard.js"));
const GroupDashboard = React.lazy(() => import("./views/Dashboard/GroupDashboard.js"));
const ReferralCode = React.lazy(() => import("./views/Dashboard/ReferralCode.js"));
const TransferPoints = React.lazy(() => import("./views/TransferPoints/TransferPoints.js"));
const RequestPoints = React.lazy(() => import("./views/RequestPoints/RequestPoints.js"));
const PreviousGameWinner = React.lazy(() =>
  import("./views/Dashboard/PreviousGameWinner.js")
);
const Wallet = React.lazy(() => import("./views/Wallet/Wallet.js"));
const User = React.lazy(() => import("./views/User/User.js"));
const GameBoard = React.lazy(() => import("./views/GameBoard/GameBoard.js"));
const LastPlayed = React.lazy(() => import("./views/LastPlayed/LastPlayed.js"));
const GameType = React.lazy(() => import("./views/GameType/GameType.js"));
// const RechargeWallet = React.lazy(() => import("./views/rechargeWallet/RechargeWallet.js"));
const RechargePage = React.lazy(() => import("./views/rechargeWallet/RechargePage.js"));
const CreateGroup = React.lazy(() =>
  import("./views/CreateGroup/CreateGroup.js")
);
const ScheduleGame = React.lazy(() =>
  import("./views/ScheduleGame/ScheduleGame.js")
);
const EditGame = React.lazy(() =>
  import("./views/ScheduleGame/EditGame.js")
);
const CloneGame = React.lazy(() =>
  import("./views/ScheduleGame/CloneGame.js")
);
const ManageGame = React.lazy(() =>
import("./views/ScheduleGame/ManageGames.js")
);
const ShareGame = React.lazy(() =>
  import("./views/ScheduleGame/ShareGame.js")
);
const ManageGroup = React.lazy(() =>
  import("./views/CreateGroup/ManageGroup.js")
);
const LinkDashboard = React.lazy(() =>
import("./views/Dashboard/LinkDashboard.js")
);

const MyClaimModal = {
  content: {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      boxShadow: "0 3px 12px rgba(0, 0, 0, 0.3)",
      height: "164px",
      width: "94%",
      maxWidth: "400px",
      margin: "auto",
      padding: "18px",
      zIndex : "99"   
      }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authTokens: "",
      showDropDown: false,
      userId : '',
      openTokenExpireModal : false,
      gameStarted: false,
      requested: false
    };
    
  }
  container = React.createRef();

  existingTokens = this.props.simpleReducer;
  userId = this.props.simpleReducer.otpVerify.userId;
  
  componentWillMount () {

    var appID = "2056378cc0ec9c0";
    var region = "us";
    var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
    CometChat.init(appID, appSetting).then(
      () => {
        console.log("Initialization completed successfully");
        // You can now call login function.
      },
      error => {
        console.log("Initialization failed with error:", error);
        // Check the reason for error and take appropriate action.
      }
    );
    
    // if(window.location.pathName == "/public/game/*"){
      
    //   this.props.getStatus(this.props.match.params.id)
    // }
    // if(window.location.pathName == "/public/group/*"){
    //   this.props.history.push(`/dashboard/${this.props.match.params.id}`)
    // }

    this._isMounted = true;
    window.onpopstate = ()=> {
        if(this.props && this.props.match && this.props.match.path == "/gameboard") {
            let exit = window.confirm("Are you the sure you want to exit?")
            if(!exit){
                this.props.history.replace("/gameboard");                
            }
            else {
                this.props.match.path = this.props.history.location.pathName
            }
        }
        
    } 
    
    this.setState({ userId : this.props.simpleReducer.otpVerify.userId})

 

    this.setState({ authTokens: this.props.simpleReducer.otpVerify.token });
    
    
    document.addEventListener("mousedown", this.handleClickOutside);

    

  }

  submitApprovalStatus = (value,gameId,ticketNo)  => {
    this.props.submitApprovalStatus(value,gameId,ticketNo)
  }
  joinGamewithApproval = (gameId,ticketNo,userId) => {
    this.props.joinGame(userId,gameId,ticketNo)
  }
  
  componentWillReceiveProps = async(nextProps) => {
    if(nextProps.simpleReducer.tokenExpires !== this.props.simpleReducer.tokenExpires){
      if(nextProps.simpleReducer.tokenExpires == "SUCCESS"){
        // console.log('999999999999999 111111',nextProps.simpleReducer.tokenExpires)
        NotificationManager.warning('Session expires! Please login again.');
        await this.sleep(2000);
        window.location.replace('/')
      }
    }

    if (
      nextProps.simpleReducer.otpVerify !== this.props.simpleReducer.otpVerify
    ) {
      this.setState({ authTokens: nextProps.simpleReducer.otpVerify.token });
    }
  }

  sleep(ms){
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        showDropDown: false,
      });
    }
  };

  render() {
    return (
      <>
        <BrowserRouter>
        {console.log('window.location.pathname !== "/"',window.location.pathname.indexOf('/public/game'))}
          <React.Suspense fallback={loading()}>
            {window.location.pathname !== "/gameboard" &&
            window.location.pathname !== "/" && (window.location.pathname.indexOf('/public/game') == -1 || window.location.pathname.indexOf('/group-dashboard') != -1 ) ? (
              <header ref={this.container}>
                <div class="container-fluid">
                  <p class="logo">
                  <Link to="/group-dashboard"><img src={logo} /></Link>
                  </p>
                  <div class="right-icon">
                  {/* <div class="mobile-el-display icon-font"><p class="icon-desc">Home</p></div> */}
                  <a data-tip="Home">
                    <Link to="/group-dashboard">
                      <img src={homeImg} />
                    </Link>
                  </a>
                  
                  <ReactTooltip place="bottom" type="dark" effect="float"/>
                  {console.log('this.props.simpleReducer && this.props.simpleReducer.otpVerify',this.props.simpleReducer && this.props.simpleReducer.otpVerify)}
                  {this.props.simpleReducer && this.props.simpleReducer.otpVerify && this.props.simpleReducer.otpVerify.userType == 'ADMIN' && <>
                  {/* <div class="mobile-el-display icon-font"><p class="icon-desc">Transfer Points</p></div> */}
                  <a data-tip="Transfer Points">
                  <Link to="/transfer-points">
                     <img src={PointsImg} />
                    </Link> </a>
                    
                     </>}
                     {/* <div class="mobile-el-display icon-font"><p class="icon-desc">Create & View Games</p></div> */}
                    <a data-tip="Create and View Games">
                    <Link to="/manageGame">
                      <img src={addgameImg} />
                    </Link>
                    </a>
                  {/* <div class="mobile-el-display icon-font"><p class="icon-desc">Wallet</p></div>                     */}
                    <a data-tip="Wallet">                    
                    <Link to="/wallet">
                      <img src={walletImg} />
                    </Link>
                    </a>
                  {/* <div class="mobile-el-display icon-font"><p class="icon-desc">Redeem Points</p></div>                   */}
                    
                    <a data-tip="Redeem Points">
                    <Link to="/request-points">
                      <img src={callImg} />
                    </Link>
                    </a>
                    {/* <div class="mobile-el-display icon-font"><p class="icon-desc">My Account</p></div> */}
                    <a data-tip="My Account">
                   
                    <div class="dropdown user_profile">
                      <a
                        onClick={() => {
                          this.state.showDropDown
                            ? this.setState({ showDropDown: false })
                            : this.setState({ showDropDown: true });
                        }}
                        class="dropdown-toggle"
                      >
                        <img src={userImg} />
                      </a>
                      
                      {this.state.showDropDown && (
                        
                        <ul class="dropdown-menu">
                          <Link to="/user">
                            <li class="dropdown-item" onClick={() => { this.setState({showDropDown: false})}}>Edit Profile</li>
                          </Link>
                          <Link to="/referralCode">
                            <li class="dropdown-item" onClick={() => { this.setState({showDropDown: false})}}>View Referral Code</li>
                          </Link>
                          <Link to="/group-dashboard">
                            <li class="dropdown-item" onClick={() => { this.setState({showDropDown: false})}}>Home</li>
                          </Link>
                          <Link to="/last-played-games">
                            <li class="dropdown-item" onClick={() => { this.setState({showDropDown: false})}}>Last played games</li>
                          </Link>
                          {this.props.simpleReducer && this.props.simpleReducer.otpVerify && this.props.simpleReducer.otpVerify.userType === 'ADMIN' && <>
                            <Link to="/transfer-points">
                            <li class="dropdown-item" onClick={() => { this.setState({showDropDown: false})}}>Transfer Points</li>
                            </Link>
                          </>}
                          <Link to="/manage-group">
                          <li class="dropdown-item" onClick={() => { this.setState({showDropDown: false})}}>Manage Groups</li>
                          </Link>
                          <Link to="/manageGame">
                          <li class="dropdown-item" onClick={() => { this.setState({showDropDown: false})}}>Create & View Game</li>
                          </Link>
                          <Link to="/wallet">
                          <li class="dropdown-item" onClick={() => { this.setState({showDropDown: false})}}>Wallet</li>
                          </Link>
                          <Link to="/request-points">
                          <li class="dropdown-item" onClick={() => { this.setState({showDropDown: false})}}>Redeem Points</li>
                          </Link>
                          <Link to="">
                          <li class="dropdown-item" onClick={() => { ls.clear(); this.setState({showDropDown: false}); window.location.replace('/')}}>Sign out</li>
                         </Link>
                         
                        </ul>
                        
                      )}
                    </div>
                    </a>
                  </div>
                </div>
              </header>
            ) :  (
              <header>
                <div class="container-fluid">
                {window.location.pathname !== "/" && window.location.pathname.indexOf('/public/game') == -1  ?
                <Link to={'/group-dashboard'}><p class="logo">
                 <img src={logo} />
                  </p></Link>:
                  <p class="logo">
                  <img src={logo} />
                   </p>}
                  {(window.location.pathname.indexOf('/gameboard') != -1) &&<div class="right-icon">
                  <Link to="/group-dashboard">
                      <img src={homeImg} />
                    </Link>
                     
                      <img src={refreshImg} onClick={()  => window.location.reload()} class="refesh-icon"/>
                      
                    </div>}
                    </div>
              </header>
            )}
            
            
            <Switch>              
              <Route
                exact
                path="/"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route                
                path="/public/group/:id"
                name="Login Page"
                render={(props) =>  <Login {...props} />}
              />
              <Route                
                path="/public/game/:id"
                name="Login Page"
                render={(props) =>  <Login {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
              exact
                path="/sign-up"
                name="SignUp"
                render={(props) => <SignUp {...props} />}
              />
              <Route                
                path="/sign-up/public/group/:id"
                name="SignUp"
                render={(props) =>  <SignUp {...props} />}
              />
              <Route
              exact
                path="/reset-password"
                name="ResetPassword"
                render={(props) => <ResetPassword {...props} />}
              />
              <Route                
                path="/reset-password/public/group/:id"
                name="ResetPassword"
                render={(props) =>  <ResetPassword {...props} />}
              />
              <Route                
                path="/sign-up/public/game/:id"
                name="SignUp"
                render={(props) =>  <SignUp {...props} />}
              />
              
              {/* <PrivateRoute
                token={this.state.authTokens}
                path="/dashboard"
                component={Dashboard}
              /> */}
              <PrivateRoute
                token={this.state.authTokens}
                path="/dashboard/:id"
                component={Dashboard}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/last-winners/:id"
                component={PreviousGameWinner}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/edit-group/:id"
                component={EditGroup}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/transfer-points"
                component={TransferPoints}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/request-points"
                component={RequestPoints}
              />
              <PrivateRoute
              exact
                token={this.state.authTokens}
                path="/group-dashboard"
                component={GroupDashboard}
              />
              <PrivateRoute
              exact
                token={this.state.authTokens}
                path="/referralCode"
                component={ReferralCode}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/group-dashboard/public/game/:id"
                component={LinkDashboard}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/scheduleGame"
                component={ScheduleGame}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/manageGame"
                component={ManageGame}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/edit-game/:id"
                component={EditGame}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/clone/:id"
                component={CloneGame}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/shareGame/:id"
                component={ShareGame}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/gameboard"
                component={GameBoard}
              />
              {/* <PrivateRoute
                token={this.state.authTokens}
                path="/lastGameWinners"
                component={PreviousGameWinner}
              /> */}
              <PrivateRoute
                token={this.state.authTokens}
                path="/wallet"
                name="Wallet"
                component={Wallet}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/payment-successful/:orderId"
                name="Wallet"
                component={Wallet}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/rechargePage"
                name="RechargePage"
                component={RechargePage}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/user/public/group/:id"
                name="User"
                component={User}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/user/public/game/:id"
                name="User"
                component={User}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/user"
                name="User"
                component={User}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/last-played-games"
                name="LastPlayed"
                component={LastPlayed}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/games"
                name="GameType"
                component={GameType}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/manage-group"
                name="ManageGroup"
                component={ManageGroup}
              />
              <PrivateRoute
                token={this.state.authTokens}
                path="/create-group"
                name="CreateGroup"
                component={CreateGroup}
              />
              {console.log('this.props app',this.props)}
              {/* <PrivateRoute exact path="/sign-up" name="SignUp" Component={SignUp} /> */}
              {/* <Route exact path="/sign-up" name="SignUp" render={props => <SignUp {...props} />} /> */}
            </Switch>
           {window.location.pathname !== "/gameboard" &&
            window.location.pathname !== "/" && <footer>
            <div>
              <ul>
                <li>
                  <a href="https://gotambola.com/terms-of-service/">Terms & Conditions</a>
                </li>
                <li>
                  <a href="https://gotambola.com/privacy-policy-2/">Privacy Policy</a>
                </li>
                <li>
                  <a href="https://gotambola.com/dividends/">Dividends Descriptions</a></li>  
              </ul>
            </div>
            
            </footer>}
          </React.Suspense>
        </BrowserRouter>
        <NotificationContainer />
        <Modal
          isOpen={this.state.openTokenExpireModal}
          contentLabel="Example Modal"
          class="myModal"
          style={MyClaimModal}
        >
          <div>
            {/* {console.log('99999999999999999999 22222222222222222')} */}
            <p class="modalText">Your Authorization Expires</p>
            <button class="claimIntiated btn" onClick={() => this.props.history.push('/') }>Sign In Again</button>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state = {}, ownProps) => {
  return {
    simpleReducer: state.simpleReducer,
  };
};

const mapDispatchToProps = {
  ...Action,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
