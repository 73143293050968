import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";

const PrivateRoute = ({ component: Component, token, ...rest }) => {
    return <Route exact {...rest} render={(props) => {
       
      if (token) {      
        return <Component {...props} />
      } else {
        return <Redirect to='/' />
      }
    }
    } />
  }

export default PrivateRoute;