import {
  REQUEST_OTP_SUCCESS,
  REQUEST_OTP_FAIL,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL ,
  FETCH_GAMES_LIST_SUCCESS,
  FETCH_GAMES_LIST_FAIL,
  JOIN_GAME_SUCCESS,
  JOIN_GAME_FAIL,
  USERS_LIST_FAIL,
  USERS_LIST_SUCCESS,
  USERS_CLAIM_LIST_SUCCESS,
  USERS_CLAIM_LIST_FAIL,
  GET_GAMES_RULES_FAIL,
  GET_GAMES_RULES_SUCCESS,
  SEND_DABB_NO_FAIL,
  SEND_DABB_NO_SUCCESS,
  CLAIM_REQUEST_SUCCESS,
  CLAIM_REQUEST_FAIL,
  VIEW_WALLET_FAIL,
  VIEW_WALLET_SUCCESS,
  WALLET_TRANSACTION_FAIL,
  WALLET_TRANSACTION_SUCCESS,
  VIEW_PROFILE_SUCCESS,
  VIEW_PROFILE_FAIL,
  GET_RANDOM_NUMBERS_SUCCESS,
  GET_RANDOM_NUMBERS_FAIL,
  LAST_PLAYED_LIST_SUCCESS,
  LAST_PLAYED_LIST_FAIL,
  GAME_INFORMATION_SUCCESS,
  GAME_INFORMATION_FAIL,
  GAME_TYPE_DETAILS_SUCCESS,
  GAME_TYPE_DETAILS_FAIL,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  SCHEDULE_GAME_FAIL,
  SCHEDULE_GAME_SUCCESS,
  RETRIEVE_TICKETS_FAIL,
  RETRIEVE_TICKETS_SUCCESS,
  GAME_WINNER_FAIL,
  GAME_WINNER_SUCCESS,
  GET_IMAGE_FAIL,
  GET_IMAGE_SUCCESS,
  GET_MASTER_RULES_SUCCESS,
  GET_MASTER_RULES_FAIL,
  GET_DATE_SUCCESS,
  GET_DATE_FAIL,
  GET_ALL_GROUP_SUCCESS,
  GET_ALL_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  DELETE_PARTICIPANTS_SUCCESS,
  DELETE_PARTICIPANTS_FAIL,
  GET_MASTER_GROUPS_SUCCESS,
  GET_MASTER_GROUPS_FAIL,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  LAST_PLAYED_GAMES_PAGINATION_SUCCESS,
  LAST_PLAYED_GAMES_PAGINATION_FAIL,
  UPDATE_IMAGE_FAIL,
  UPDATE_IMAGE_SUCCESS,
  GROUP_DETAILS_FAIL,
  GROUP_DETAILS_SUCCESS,
  CREDIT_POINTS_SUCCESS,
  CREDIT_POINTS_FAIL,
  USER_STATUS_SUCCESS,
  USER_STATUS_FAIL,
  GET_GROUP_WISE_GAMES_SUCCESS,
  GET_GROUP_WISE_GAMES_FAIL,
  GET_ALL_GROUPS_LIST_SUCCESS,
  GET_ALL_GROUPS_LIST_FAIL,
  GET_APPROVAL_REQUESTS_SUCCESS,
  GET_APPROVAL_REQUESTS_FAIL,
  SUBMIT_APPROVAL_STATUS_SUCCESS,
  SUBMIT_APPROVAL_STATUS_FAIL,
  TOKEN_EXPIRES,
  LAST_FIVE_NUMBERS_FAIL,
  LAST_FIVE_NUMBERS_SUCCESS,
  START_GAME_SUCCESS,
  START_GAME_FAIL,
  GET_GAME_STATUS_SUCCESS,
  GET_GAME_STATUS_FAIL,
  GET_PARTICIPANT_IMAGE_SUCCESS,
  GET_PARTICIPANT_IMAGE_FAIL,
  RULES_SETTING_SUCCESS,
  RULES_SETTING_FAIL,
  REQUEST_MONEY_SUCCESS,
  REQUEST_MONEY_FAIL,
  GET_USERS_GAMES_SUCCESS,
  GET_USERS_GAMES_FAIL,
  GET_GAME_DETAILS_SUCCESS,
  GET_GAME_DETAILS_FAIL,
  GAME_DELETE_SUCCESS,
  GAME_DELETE_FAIL,
  GET_USER_PLAYING_GAME_SUCCESS,
  GET_USER_PLAYING_GAME_FAIL,
  GET_USER_TICKET_LIST_SUCCESS,
  GET_USER_TICKET_LIST_FAIL,
  RECHARGE_SUCCESS,
  RECHARGE_FAIL,
  GET_TRANSACTION_STATUS_SUCCESS,
  GET_TRANSACTION_STATUS_FAIL,
  VERIFY_PASSWORD_SUCCESS,
  VERIFY_PASSWORD_FAIL,
  REQUEST_RESET_PASS_OTP_SUCCESS,
  REQUEST_RESET_PASS_OTP_FAIL,
  RESET_PASS_FAIL,
  RESET_PASS_SUCCESS,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_FAIL,
  REFRESH_WALLET_TRANSACTION_SUCCESS,
  REFRESH_WALLET_TRANSACTION_FAIL,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL
} from "../constants/ActionType"
import ls from 'local-storage'

const apiStatus = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
}

const INIT_STATE ={
  otpRequest : undefined,
  otpVerify : {
    token : ls.get('authTokens'),
    userId: ls.get('userId'),
    userType : ls.get('userType')
  },
  todayGamesList :undefined,
  joinStatus:undefined,
  ticketInfo:[],
  userList:[],
  gamesRuleslist : [],
  dabbNumberResult : undefined,
  claimResponse: undefined,
  walletTransactionData : undefined,
  viewWalletData : undefined,
  viewProfile : undefined,
  randomNumbersList: undefined,
  gameInformation : undefined,
  retrieveTicketsInfo : undefined,
  winnerList : undefined,
  imageResponse : undefined,
  gameTypeDetails: undefined,
  masterRulesList:undefined,
  scheduleGameResponse: undefined,
  serverDate : undefined,
  updateProfile : undefined,
  masterGroupList : undefined,
  createGroup :undefined,
  updateImage : undefined,
  updateGroupDetails: undefined,
  creditPointsList : undefined,
  userStatus : undefined,
  groupWiseGames : undefined,
  approvalRequestsList : undefined,
  approvalStatus : undefined,
  tokenExpires : undefined,
  lastFiveNumbersList: undefined,
  gameStart: undefined,
  rulesSetting: undefined,
  requestMoneyStatus : undefined,
  usersGame: undefined,
  gameUpdate : undefined,
  gameDelete : undefined,
  rechargeStatus : undefined,
  transactionStatus: undefined,
  removeUser: undefined,
  rresetPassotpRequest: undefined,
  resetPass: undefined,
  refreshResult: undefined,
  userDetails : undefined
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case 'SIMPLE_ACTION':
        return {
          ...state,
          result: action.payload
        }
      case REQUEST_OTP_SUCCESS: {
        
          return {
              ...state,
              result : action.payload,
              otpRequest : apiStatus.SUCCESS,
          }

      }
      case REQUEST_OTP_FAIL: {
        
        return {
            ...state,
            otpRequest : apiStatus.FAILED,
        }
      }
      case REQUEST_RESET_PASS_OTP_SUCCESS: {
        
        return {
            ...state,
            
            resetPassotpRequest : {status : apiStatus.SUCCESS,
              result : action.payload
            }
        }

    }
    case REQUEST_RESET_PASS_OTP_FAIL: {
      
      return {
          ...state,
          resetPassotpRequest : apiStatus.FAILED,
      }
    }
    case RESET_PASS_SUCCESS: {
        
          return {
              ...state,              
              resetPass :{
                status :apiStatus.SUCCESS,
                result : action.payload,
              } 
          }

      }
      case RESET_PASS_FAIL: {
        
        return {
            ...state,
            resetPass : apiStatus.FAILED,
        }
      }
      case VERIFY_OTP_SUCCESS: {
       
          return {
              ...state,
              result : action.payload,
              otpVerify : {
                status : apiStatus.SUCCESS,
                result : action.payload,
                userId : action.payload.data && action.payload.data.username,
                token : action.payload.headers['x-authorization'],
                userType : action.payload.data && action.payload.data.type
              },
          }

      }
      case VERIFY_OTP_FAIL: {
       
        return {
            ...state,
            otpVerify : apiStatus.FAILED,
        }
      }
      case VERIFY_PASSWORD_SUCCESS: {       
        return {
            ...state,
            result : action.payload,
            otpVerify : {
              status : apiStatus.SUCCESS,
              result : action.payload,
              userId : action.payload && action.payload.data && action.payload.data.username,
              token : action.payload && action.payload.headers['x-authorization'],
              userType : action.payload.data && action.payload.data.type
            },
        }

    }
    case VERIFY_PASSWORD_FAIL: {
     
      return {
          ...state,
          otpVerify : apiStatus.FAILED,
      }
    }
      case FETCH_GAMES_LIST_SUCCESS: {
          return {
              ...state,
              todayGamesList : action.payload,
          }

      }
      case FETCH_GAMES_LIST_FAIL: {
        ////console.log("reducer",state)
        return {
            ...state,
            todayGamesList : undefined,
        }
      }
      case JOIN_GAME_SUCCESS: {
          return {
              ...state,
              joinStatus : {
                status : apiStatus.SUCCESS,
                ticketInfo : action.payload
              }
          }

      }
      case JOIN_GAME_FAIL: {
        ////console.log("reducer",state)
        return {
            ...state,
            joinStatus : apiStatus.FAILED,
            
        }
      }
      case USERS_LIST_SUCCESS: {
        ////console.log("reducer",state)
        return {
            ...state,
            userList : action.payload
        }

      }
      case USERS_LIST_FAIL: {
        
        return {
            ...state,
            userList : apiStatus.FAILED,
            
        }
      }
      case USERS_CLAIM_LIST_SUCCESS: {
        ////console.log("reducer",state)
        return {
            ...state,
            userClaimList : action.payload
        }

      }
      case USERS_CLAIM_LIST_FAIL: {        
        return {
            ...state,
            userClaimList : apiStatus.FAILED,
            
        }
      }
      case GET_GAMES_RULES_SUCCESS: {
        ////console.log("reducer",state)
        return {
            ...state,
            gamesRuleslist : action.payload
        }

      }
      case GET_GAMES_RULES_FAIL: {        
        return {
            ...state,            
        }
      }
      case SEND_DABB_NO_SUCCESS : {
        ////console.log("reducer",state)
        return {
            ...state,
            dabbNumberResult : action.payload
        }

      }
      case SEND_DABB_NO_FAIL : {        
        return {
            ...state,            
        }
      }
      case CLAIM_REQUEST_SUCCESS : {
        return {
            ...state,
            claimResponse : action.payload
        }

      }
      case CLAIM_REQUEST_FAIL : {        
        return {
            ...state,
            claimResponse : action.payload            
        }
      }
      case WALLET_TRANSACTION_SUCCESS: {
        ////console.log("reducer", state);
        return {
          ...state,
          walletTransactionData: action.payload,
        };
      }
      case WALLET_TRANSACTION_FAIL: {
        return {
          ...state,
          walletTransactionData: apiStatus.FAILED,
        };
      }
      case VIEW_WALLET_SUCCESS: {
        ////console.log("hii reducer", state);
        return {
          ...state,
          viewWalletData: action.payload,
        };
      }
      case VIEW_WALLET_FAIL: {
        return {
          ...state,
        };
      }
      case VIEW_PROFILE_SUCCESS: {
        
          return {
              ...state,
              viewProfile : action.payload,
          }

      }
      case VIEW_PROFILE_FAIL: {
       
        return {
            ...state,
        }
      }
      case GET_RANDOM_NUMBERS_SUCCESS: {
        
        return {
            ...state,
            randomNumbersList : action.payload,
        }

      }
      case GET_RANDOM_NUMBERS_FAIL: {
      
        return {
            ...state,
        }
      }
      case LAST_PLAYED_LIST_SUCCESS: {
        
        return {
          ...state,
          lastPlayedList: action.payload,
        };
      }
      case LAST_PLAYED_LIST_FAIL: {
        return {
          ...state,
        };
      }
      case GAME_INFORMATION_SUCCESS: {
        // console.log("state at reducer", {
        //   ...state,
        //   gameInformationStatus: apiStatus.SUCCESS,
        // });
        return {
          ...state,
          gameInformation: action.payload,
        };
      }
      case GAME_INFORMATION_FAIL: {
        return {
          ...state,
        };
      }
      case GAME_TYPE_DETAILS_SUCCESS: {
        
        return {
          ...state,
          gameTypeDetails: action.payload,
        };
      }
      case GAME_TYPE_DETAILS_FAIL: {
        return {
          ...state,
        };
      }
      case UPDATE_PROFILE_SUCCESS: {
        return {
            ...state,
            updateProfile : {
              status : apiStatus.SUCCESS,
              result  :action.payload
            }
        }
      }
      case UPDATE_PROFILE_FAIL: {
        return {
          ...state,
          updateProfile : apiStatus.FAILED,
        }
      }
      case SCHEDULE_GAME_SUCCESS: {
        return {
            ...state,
            scheduleGameResponse : {
              status :apiStatus.SUCCESS,
              result :action.payload
            
        }
      }
    }
      case SCHEDULE_GAME_FAIL: {
          return {
            ...state,
            scheduleGameResponse : apiStatus.FAILED,
        }
      }
      case RETRIEVE_TICKETS_SUCCESS: {
        return {
            ...state,
            retrieveTicketsInfo : action.payload
        }
      }
      case RETRIEVE_TICKETS_FAIL: {
          return {
            ...state,
            // retrieveTicketsInfo : apiStatus.FAILED,
        }
      }
      case GAME_WINNER_SUCCESS: {
          return {
              ...state,
              winnerList : action.payload,
          }

      }
      case GAME_WINNER_FAIL: {
        return {
            ...state,
            winnerList : apiStatus.FAILED,
        }
      }
      case GET_IMAGE_SUCCESS : {
          return {
              ...state,
              imageResponse : action.payload,
          }

      }
      case GET_IMAGE_FAIL : {
        return {
            ...state,
        }
      }
      case GET_MASTER_RULES_SUCCESS : {
          return {
              ...state,
              masterRulesList : action.payload,
          }

      }
      case GET_MASTER_RULES_FAIL : {
        return {
            ...state,
        }
      }
      case GET_DATE_SUCCESS : {
        return {
            ...state,
            serverDate : action.payload,
        }

      }
      case GET_DATE_FAIL : {
        return {
            ...state,
        }
      }
      case GET_ALL_GROUP_SUCCESS: {
        return {
          ...state,
          allGroupList: action.payload,
        };
      }
      case GET_ALL_GROUP_FAIL: {
        return {
          ...state,
        };
      }
      case DELETE_GROUP_SUCCESS: {
        return {
          ...state,
          deletedGroup: {
            status: apiStatus.SUCCESS,
            result: action.payload,
          },
        };
      }
      case DELETE_GROUP_FAIL: {
        return {
          ...state,
          deletedGroup: {
            status: apiStatus.SUCCESS,
          },
        };
      }
      case DELETE_PARTICIPANTS_SUCCESS: {
        return {
          ...state,
          deletedParticipants: {
            status: apiStatus.SUCCESS,
            result: action.payload,
          },
        };
      }
      case DELETE_PARTICIPANTS_FAIL: {
        return {
          ...state,
          deletedParticipants: {
            status: apiStatus.SUCCESS,
          },
        };
      }
      case GET_MASTER_GROUPS_SUCCESS: {
        return {
          ...state,
          masterGroupList: action.payload,
        };
      }
      case GET_MASTER_GROUPS_FAIL: {
        return {
          ...state,
        };
      }
      case CREATE_GROUP_SUCCESS: {
        return {
          ...state,
          createGroup: {
            status : apiStatus.SUCCESS,
            result :action.payload
          } 
        };
      }
      case CREATE_GROUP_FAIL: {
        return {
          ...state,
          createGroup :apiStatus.FAILED
        };
      }
      case UPDATE_GROUP_SUCCESS: {
        return {
          ...state,
          updateGroupDetails: {
            status : apiStatus.SUCCESS,
            result : action.payload
          }
        };
      }
      case UPDATE_GROUP_FAIL: {
        return {
          ...state,
          updateGroupDetails : apiStatus.FAILED
        };
      }
      case LAST_PLAYED_GAMES_PAGINATION_SUCCESS: {
        return {
          ...state,
          lastPlayedGamesPagination: action.payload,
        };
      }
      case LAST_PLAYED_GAMES_PAGINATION_FAIL: {
        return {
          ...state,
        };
      }
      case UPDATE_IMAGE_SUCCESS: {
        return {
            ...state,
            updateImage : {
              status : apiStatus.SUCCESS,
              result  :action.payload
            }
        }
      }
      case UPDATE_IMAGE_FAIL: {
        return {
          ...state,
          updateImage : apiStatus.FAILED,
        }
      }
      case GROUP_DETAILS_SUCCESS: {
        return {
          ...state,
          groupDetailsList: action.payload,
        };
      }
      case GROUP_DETAILS_FAIL: {
        return {
          ...state,
        };
      }
      case CREDIT_POINTS_SUCCESS: {
        return {
          ...state,
          creditPointsList:{
            status:apiStatus.SUCCESS,
            result :  action.payload
          }
        };
      }
      case CREDIT_POINTS_FAIL: {
        return {
          ...state,
          creditPointsList : apiStatus.FAILED
        };
      }
      case USER_STATUS_SUCCESS: {
        return {
          ...state,
          userStatus:{
            status: apiStatus.SUCCESS,
            result :  action.payload
          }
        };
      }
      case USER_STATUS_FAIL: {
        return {
          ...state,
          userStatus : apiStatus.FAILED
        };
      }
      case GET_GROUP_WISE_GAMES_SUCCESS: {
        return {
          ...state,
          groupWiseGames:{
            status:apiStatus.SUCCESS,
            result :  action.payload
          }
        };
      }
      case GET_GROUP_WISE_GAMES_FAIL: {
        return {
          ...state,
          groupWiseGames : apiStatus.FAILED
        };
      }
      case GET_ALL_GROUPS_LIST_SUCCESS: {
        return {
          ...state,
          groupsList: action.payload
           
        };
      }
      case GET_ALL_GROUPS_LIST_FAIL: {
        return {
          ...state,
        };
      }
      case GET_APPROVAL_REQUESTS_SUCCESS: {
        return {
          ...state,
          approvalRequestsList: action.payload
           
        };
      }
      case GET_APPROVAL_REQUESTS_FAIL: {
        return {
          ...state,
        };
      }
      case SUBMIT_APPROVAL_STATUS_SUCCESS: {
        return {
          ...state,
          approvalStatus: action.payload
           
        };
      }
      case SUBMIT_APPROVAL_STATUS_FAIL: {
        return {
          ...state,
        };
      }
      case TOKEN_EXPIRES: {
        console.log('99999999999999')
        return {
          ...state,
          tokenExpires : apiStatus.SUCCESS,

        };
      }
      case LAST_FIVE_NUMBERS_SUCCESS: {
        return {
          ...state,
          lastFiveNumbersList: action.payload,
        };
      }
      case LAST_FIVE_NUMBERS_FAIL: {
        return {
          ...state,
        };
      }
      case START_GAME_SUCCESS: {
        return {
          ...state,
          gameStart: {
            status: apiStatus.SUCCESS,
            result: action.payload
          },
        };
      }
      case START_GAME_FAIL: {
        return {
          gameStart: apiStatus.FAILED,
          ...state,
        };
      }
      case GET_GAME_STATUS_SUCCESS: {
        return {
          ...state,
          gameStatus: action.payload
        };
      }
      case GET_GAME_STATUS_FAIL: {
        return {
          ...state,
        };
      }
      case GET_PARTICIPANT_IMAGE_SUCCESS: {
        return {
          ...state,
          participantImage: action.payload
        };
      }
      case GET_PARTICIPANT_IMAGE_FAIL: {
        return {
          ...state,
        };
      }
      case RULES_SETTING_SUCCESS: {
        return {
          ...state,
          rulesSetting: action.payload
        };
      }
      case RULES_SETTING_FAIL: {
        return {
          ...state,
        };
      }
      case REQUEST_MONEY_SUCCESS: {
        return {
          ...state,
          requestMoneyStatus: {
            status : apiStatus.SUCCESS,
            result : action.payload
          }
        };
      }
      case REQUEST_MONEY_FAIL: {
        return {
          ...state,
          requestMoneyStatus: apiStatus.FAILED
        };
      }
      case GET_USERS_GAMES_SUCCESS: {
        return {
          ...state,
          usersGame : {
            status :apiStatus.SUCCESS,
            result : action.payload
          }
        };
      }
      case GET_USERS_GAMES_FAIL: {
        return {
          ...state,
        };
      }
      case GET_GAME_DETAILS_SUCCESS: {
        return {
          ...state,
          gameUpdate : {
            status :apiStatus.SUCCESS,
            result : action.payload
          }
        };
      }
      case GET_GAME_DETAILS_FAIL: {
        return {
          ...state,
        };
      }
      case GAME_DELETE_SUCCESS: {
        return {
          ...state,
          gameDelete : apiStatus.SUCCESS
        };
      }
      case GAME_DELETE_FAIL: {
        return {
          ...state,
          gameDelete : apiStatus.FAILED
        };
      }      
      case GET_USER_PLAYING_GAME_SUCCESS: {
        return {
          ...state,
          userPlayingList: action.payload
        };
      }
      case GET_USER_PLAYING_GAME_FAIL: {
        return {
          ...state,
          userPlayingList : apiStatus.FAILED,
        };
      }
      case GET_USER_TICKET_LIST_SUCCESS: {
        return {
          ...state,
          ticketList: action.payload
        };
      }
      case GET_USER_TICKET_LIST_FAIL: {
        return {
          ...state,
          ticketList : apiStatus.FAILED,
        };
      }
      case RECHARGE_SUCCESS: {
        return {
          ...state,
          rechargeStatus : {
            status : apiStatus.SUCCESS,
            result : action.payload 
          }
        };
      }
      case RECHARGE_FAIL: {
        return {
          ...state,
          rechargeStatus : apiStatus.FAILED,
        };
      }
      case GET_TRANSACTION_STATUS_SUCCESS: {
        return {
          ...state,
          transactionStatus : action.payload
        }
      }
      case GET_TRANSACTION_STATUS_FAIL :{
        return {
          ...state
        }
      }
      case REMOVE_USER_SUCCESS: {
        return {
          ...state,
          removeUser : action.payload
        }
      }
      case REMOVE_USER_FAIL :{
        return {
          ...state
        }
      }
      case REFRESH_WALLET_TRANSACTION_SUCCESS: {
        return {
          ...state,
          refreshResult : action.payload
        }
      }
      case REFRESH_WALLET_TRANSACTION_FAIL :{
        return {
          ...state
        }
      }
      case USER_DETAILS_SUCCESS: {
        return {
          ...state,
          userDetails : action.payload
        }
      }
      case USER_DETAILS_FAIL :{
        return {
          ...state
        }
      }
      default:
        return state
      }
   }
